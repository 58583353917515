import { Drawer } from '@mui/material';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiLanguagePicker, useIsMobile } from 'noddi-ui';
import { DrawerContent, PaperProps } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/SideNav';
import { useMobileNav } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/useMobileNav';
import { useFilteredNavItems } from 'noddi-ui/src/components/Elements/Layouts/useFilteredNavItems';
import { cn } from 'noddi-ui/src/helpers/utilts';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { getSupportedLocales } from '../App';
import routes from '../appRoutes';
import { HomeHeaderNav } from '../components/Layouts/HomeHeaderNav';
import ImpersonateUserButton from '../components/UserData/ImpersonateUserButton';
import useNavRoutes from '../hooks/useNavItems';

export function HomeLayout() {
  const { tokenLoginError, userData, isLoggedIn, isTokenLoginLoading } = useAuthContext();
  const { getNavItems } = useNavRoutes();

  const { pathname } = useLocation();
  const mobileNav = useMobileNav();
  const isMobile = useIsMobile();

  const filteredNavItems = useFilteredNavItems(getNavItems());

  //Closes the MobileNav after user clicks one of the menu items (route change)
  useEffect(() => {
    mobileNav.handleClose();
  }, [pathname]);

  if (!userData && isTokenLoginLoading) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getPath()} />;
  }

  if (tokenLoginError) {
    if (tokenLoginError?.response?.status === 401) {
      return <Navigate to={routes.login.getPath()} />;
    }
  }

  // Need the timer picker gradient on tier hotel edit timewindow page
  const isBookingEditPath = pathname.startsWith('/home/bookings') && pathname.endsWith('edit');
  const isWCForTireStorageBooking = pathname.includes(routes.tireStorageBooking.getPath());

  const applyGradient = (isWCForTireStorageBooking || isBookingEditPath) && isMobile;

  return (
    <div className={cn('min-h-screen bg-peach-to-purple', applyGradient && 'bg-pinto-to-white-gradient')}>
      <HomeHeaderNav onNavOpen={mobileNav.handleOpen} />

      <Drawer
        anchor='left'
        onClose={mobileNav.handleClose}
        open={mobileNav.open}
        PaperProps={PaperProps()}
        variant='temporary'
      >
        <DrawerContent
          navItems={filteredNavItems}
          LanguagePicker={<NoddiLanguagePicker supportedLanguages={getSupportedLocales()} />}
          CustomNavElement={ImpersonateUserButton}
          customPathToProfile={routes.profile.getPath()}
        />
      </Drawer>

      <Outlet />
    </div>
  );
}
